import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import logger from '@reusables/logger'
import App from './App.vue'
import './index.css'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'

const head = createHead()
const app = createApp(App)
app.use(head)
app.use(store)
app.use(router)
app.use(logger)
// sentry makes it difficult to debug in local
if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		app,
		dsn: 'https://05c5e550d4b21755cb5cc6ce38d70a0b@o4507429399298048.ingest.us.sentry.io/4507429400608768',
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		environment: process.env.NODE_ENV
	})
}

app.mount('#app')
