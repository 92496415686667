<template>
	<div class="fixed top-0 left-0 w-full h-screen min-h-screen overflow-y-auto z-30">
		<div class="relative grid place-items-center bg-black bg-opacity-5 w-full h-full">
			<div class="relative p-3 2xl:p-6 bg-white shadow rounded w-full">
				<div
					class="absolute top-4 right-4 cursor-pointer rounded p-2"
					v-if="!getOverlayProps?.hideClose"
					@click.prevent="closeOverlay">
					<svg
						class="w-4 h-4 text-gray-400"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
							clip-rule="evenodd" />
					</svg>
				</div>
				<component
					class="w-full"
					:is="components[getOverlayComponent]"
					:name="components[getOverlayComponent]"
					:props="getOverlayProps"></component>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const components = {
	AddCourseOverlay: defineAsyncComponent(() => import('./Overlays/AddCourseOverlay.vue')),
	AddAssessmentOverlay: defineAsyncComponent(() => import('./Overlays/AddAssessmentOverlay.vue')),
	AddWebinarOverlay: defineAsyncComponent(() => import('./Overlays/AddWebinarOverlay.vue')),
	AddMeetingOverlay: defineAsyncComponent(() => import('./Overlays/AddMeetingOverlay.vue')),
	AddAccessOverlay: defineAsyncComponent(() => import('./Overlays/AddAccessOverlay.vue')),
	AddTagOverlay: defineAsyncComponent(() => import('./Overlays/AddTagOverlay.vue')),
	AddFormOverlay: defineAsyncComponent(() => import('./Overlays/AddFormOverlay.vue')),
	AddCollectionOverlay: defineAsyncComponent(() => import('./Overlays/AddCollectionOverlay.vue')),
	DeleteOverlay: defineAsyncComponent(() => import('./Overlays/DeleteOverlay.vue')),
	ConfirmationOverlay: defineAsyncComponent(() => import('./Overlays/ConfirmationOverlay.vue')),
	ArchiveOverlay: defineAsyncComponent(() => import('./Overlays/ArchiveOverlay.vue')),
	AddCategoryOverlay: defineAsyncComponent(() => import('./Overlays/AddCategoryOverlay.vue')),
	AddDomainOverlay: defineAsyncComponent(() => import('./Overlays/AddDomainOverlay.vue')),
	AddInstructorOverlay: defineAsyncComponent(() => import('./Overlays/AddInstructorOverlay.vue')),
	AddCompanyOverlay: defineAsyncComponent(() => import('./Overlays/AddCompanyOverlay.vue')),
	AddUserOverlay: defineAsyncComponent(() => import('./Overlays/AddUserOverlay.vue')),
	SimpleOverlay: defineAsyncComponent(() => import('./Overlays/SimpleOverlay.vue')),
	WebinarInfoOverlay: defineAsyncComponent(() => import('./Overlays/WebinarInfoOverlay.vue')),
	ManageSmartContent: defineAsyncComponent(() => import('./Overlays/ManageSmartContent.vue')),
	ManageResource: defineAsyncComponent(() => import('./Overlays/ManageResource.vue')),
	TaskOverlay: defineAsyncComponent(() => import('./Overlays/TaskOverlay.vue'))
}

const getOverlayComponent = computed(() => store.getters.getOverlayComponent)
const getOverlayProps = computed(() => store.getters.getOverlayProps)

function closeOverlay() {
	store.dispatch('updateAction', { action: 'cancel', data: {} })
	store.dispatch('hideOverlay')
}

defineExpose({
	components
})
</script>
