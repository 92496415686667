import Login from './Middlewares/Login'

const userRoutes = [
	{
		path: '/url-not-found',
		name: 'Not Found',
		component: () => import('../view/User/NotFound.vue'),
		meta: {
			middleware: [Login],
			access: 'any'
		}
	},
	{
		path: '/admin/',
		name: 'Login',
		beforeEnter() {
			window.location.href = '/login'
		}
	},
    {
		path: '/logout',
		name: 'Logout',
		component: () => import('../view/User/UserLogout.vue'),
	},
    {
        // user dashboard
        path: '/admin/dashboard',
        name: 'Dashboard',
        component: () => import('../view/User/UserDashboard.vue'),
        meta: {
            middleware: [Login]
        }
    },
	{
		// do not direct people here via vue router this is only for localhost
		path: '/login',
		name: 'Login',
		component: () => import('../view/User/UserAuthentication.vue'),
		meta: {
			middleware: [Login],
			access: 'any'
		}
	}
]

export default userRoutes
