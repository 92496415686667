<template>
	<div></div>
</template>

<script setup>
import { h, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useMessage, NAlert } from 'naive-ui'

const store = useStore()
const message = useMessage()

const renderMessage = (props) => {
	const { type } = props
	return h(
		NAlert,
		{
			closable: props.closable,
			onClose: props.onClose,
			type: type === 'loading' ? 'default' : type,
			title: props.title,
			style: {
				boxShadow: 'var(--n-box-shadow)',
				maxWidth: 'calc(100vw - 32px)',
				width: '480px'
			}
		},
		{
			default: () => props.content
		}
	)
}

onMounted(() => {
	store.watch(
		(state) => state.alert,
		(newAlert) => {
			if (newAlert) {
				showMessage(newAlert)
			}
		},
		{ immediate: true }
	)
})

function showMessage(alert) {
	const { type, message: alertMessage, duration } = alert

	message[type](alertMessage, {
		render: renderMessage,
		closable: true,
		duration: duration || 3000,
		title: type.charAt(0).toUpperCase() + type.slice(1) // Capitalize the type for the title
	})

	// Automatically hide the alert in Vuex store
	store.dispatch('hideAlert')
}
</script>
