import Login from './Middlewares/Login'

const editRoutes = [
	{
		path: '/admin/users/:userId',
		name: 'Edit User',
		component: () => import('../view/User/EditUser.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/images/:imagePath',
		name: 'Edit Image Template',
		component: () => import('../view/Admin/Edit/EditImages.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/learning/:courseSlug',
		name: 'Edit Course',
		component: () => import('../view/Admin/Edit/EditCourseV2.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/webinar/:webinarSlug',
		name: 'Edit Webinar',
		component: () => import('../view/Admin/Edit/EditWebinar.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/recordings/:recordingSlug',
		name: 'Edit Recording',
		component: () => import('../view/Admin/Edit/EditRecording.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/companies/:companyId',
		name: 'Edit Company',
		component: () => import('../view/Admin/Edit/EditCompany.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/forms/:formSlug',
		name: 'Edit Form',
		component: () => import('../view/Admin/Edit/EditForm.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/assessments/:assessmentSlug',
		name: 'Edit Assessment',
		component: () => import('../view/Admin/Edit/EditAssessment.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/events/:eventSlug',
		name: 'Edit Event',
		component: () => import('../view/Admin/Edit/EditEvent.vue'),
		meta: {
			middleware: [Login]
		},
		children: [
			{
				path: 'event-registrations',
				name: 'Event Registrations',
				component: () => import('../view/ViewComponents/Event/EventRegistrations.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'event-sessions',
				name: 'Event Sessions',
				component: () => import('../view/ViewComponents/Event/EventSessions.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'event-schedule',
				name: 'Event Schedule',
				component: () => import('../view/ViewComponents/Event/EventSchedule.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'event-attendees',
				name: 'Event Attendees',
				component: () => import('../view/ViewComponents/Event/EventAttendees.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'event-embed',
				name: 'Event Embed',
				component: () => import('../view/ViewComponents/Event/EventEmbed.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'event-emails',
				name: 'Event Emails',
				component: () => import('../view/ViewComponents/Event/EventEmails.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'event-dashboard',
				name: 'Event Dashboard',
				component: () => import('../view/ViewComponents/Event/EventDashboard.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'event-partners',
				name: 'Event Partners',
				component: () => import('../view/ViewComponents/Event/EventPartners.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'event-speakers',
				name: 'Event Speakers',
				component: () => import('../view/ViewComponents/Event/EventSpeakers.vue'),
				meta: {
					middleware: [Login]
				}
			},
			{
				path: 'event-settings',
				name: 'Event Settings',
				component: () => import('../view/ViewComponents/Event/EventSettings.vue'),
				meta: {
					middleware: [Login]
				}
			}
		]
	},
	{
		path: '/admin/access/:accessId',
		name: 'Edit Access',
		component: () => import('../view/Admin/Edit/EditAccess.vue'),
		meta: {
			middleware: [Login]
		}
	},
	{
		path: '/admin/pages/:pagePath',
		name: 'Edit Page',
		component: () => import('../view/Admin/Edit/EditPage.vue'),
		meta: {
			middleware: [Login]
		}
	}
]

export default editRoutes
