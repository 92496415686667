import { fetchAuthState } from '@utils/AuthService'
import { log } from '@reusables/logger'
import store from '../../store/index'

export default async function login({ to, next, router }) {
	let redirect
	await fetchAuthState()
		.then((data) => {
			if (data.role?.administrator) {
				redirect = 'next'
			}
			if (to.meta.access === 'member' && data) {
				redirect = 'next'
			}
			if (data && to.name === 'Login') {
				redirect = 'dashboard'
			}
		})
		.catch((error) => {
			log('🚀 ~ file: Login.js ~ line 20 ~ login ~ error', error)
			if (to.meta.access === 'any') {
				redirect = 'next'
			} else {
				// get the path from window location
				const path = window.location.pathname
				store.commit('setLoginRedirect', path)
				redirect = 'login'
			}
		})
	if (redirect === 'dashboard') {
		router.push({ name: 'Dashboard' })
	}
	if (redirect === 'login') {
		router.push({ name: 'Login' })
	}
	if (redirect === 'next') {
		next()
	}
}
