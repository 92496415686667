import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import 'firebase/compat/analytics'
import 'firebase/compat/app-check'

const firebaseConfig = {
	apiKey: 'AIzaSyCFE7zTVK5Sjype2zfag-n224m99LoOeog',
	authDomain: 'hubmods-lms.firebaseapp.com',
	projectId: 'hubmods-lms',
	storageBucket: 'hubmods-lms.appspot.com',
	messagingSenderId: '445991523044',
	appId: '1:445991523044:web:48dd7de56096d3dee392f1',
	measurementId: 'G-YWSM9K4HLB'
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

firebaseApp.analytics()

const functions = firebase.functions()
// functions.useEmulator('127.0.0.1', 5001)

export const db = firebaseApp.firestore()
export const auth = firebaseApp.auth()
export { functions }
export const storage = firebase.storage()
export const { firestore } = firebase
