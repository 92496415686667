<template>
	<n-notification-provider>
		<n-message-provider>
			<n-config-provider preflight-style-disabled :theme-overrides="themeOverrides">
				<div
					v-if="
						loaded &&
						(!maintenance ||
							(maintenanceMode?.tenants.length !== 0 &&
								!maintenanceMode?.tenants?.includes(tenant?.id)))
					">
					<TopNav></TopNav>
					<div class="min-h-screen relative" :style="style">
						<MainSideNav v-if="!store.state.hideNav"></MainSideNav>
						<div
							:class="
								route.name !== 'Edit Page'
									? 'w-full h-full align-top 2xl:max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-lg px-8 mx-auto py-8'
									: ''
							"
							id="router-view">
							<Alert v-if="store.getters.getAlert" />
							<Modal v-if="store.getters.getShowModal" />
							<Overlay v-if="store.getters.getShowOverlay" />
							<router-view />
						</div>
					</div>
				</div>
				<div
					v-if="
						maintenance &&
						(maintenanceMode?.tenants.length === 0 || maintenanceMode.tenants.includes(tenant?.id))
					">
					<div class="flex items-center justify-center h-screen">
						<div class="flex flex-col items-center justify-center">
							<img v-if="tenant?.branding?.logo" :src="tenant?.branding?.logo" class="w-32 mb-4" />
							<div class="text-3xl font-bold text-center mb-4">
								{{ tenant?.name }} Maintenance Mode
							</div>
							<div class="text-center mb-4" v-if="maintenanceMode.description">
								{{ maintenanceMode.description }}
							</div>
							<div class="text-center mb-4" v-if="maintenanceMode.status">
								{{ maintenanceMode.status }}
							</div>
						</div>
					</div>
				</div>
			</n-config-provider>
		</n-message-provider>
	</n-notification-provider>
</template>

<script setup>
import { ref, computed, onMounted, provide, watch } from 'vue'
import { saveDocument } from '@utils/DataService'
import MainSideNav from '@ViewComponents/Shared/MainSideNav.vue'
import TopNav from '@ViewComponents/Shared/TopNav.vue'
import Alert from '@ViewComponents/Shared/Popovers/AlertPop.vue'
import Modal from '@ViewComponents/Shared/Popovers/ModalPop.vue'
import Overlay from '@ViewComponents/Shared/Popovers/OverlayPop.vue'
import { useBrowserLocation } from '@vueuse/core'
import { db, functions } from '@root/firebaseDatabase'
import { DateTime } from 'luxon'
import { NConfigProvider, NNotificationProvider, NMessageProvider } from 'naive-ui'
import Gleap from 'gleap'
import * as Sentry from '@sentry/vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const loaded = ref(false)
const country = ref(undefined)
const maintenanceMode = ref({
	active: false,
	description: '',
	status: '',
	tenants: []
})
const maintenance = ref(false)
const themeOverrides = ref({
	Carousel: {
		dotColor: '#c0c0c0',
		dotColorActive: '#000000'
	}
})

provide(
	'user',
	computed(() => store.state.userData)
)
provide(
	'tenant',
	computed(() => store.state.tenant)
)

onMounted(() => {
	const xhttp = new XMLHttpRequest()
	xhttp.onreadystatechange = function state() {
		if (this.readyState === 4 && this.status === 200) {
			country.value = JSON.parse(this.responseText).country
			store.commit('setCountry', country.value)
		}
	}
	xhttp.open('GET', 'https://restless-disk-8af0.komunily.workers.dev/', true)
	xhttp.send()
})

const style = computed(
	() => `
  --primary-color: #0075FF;
  --primary-hover: #3994FF;
  --primary-background: #0075FF;
  --secondary-color: #000000;
  --success-color: #166534;
  --danger-color: #b91c1c;
  --warning-color: #ca8a04;
  --info-color: #1d4ed8;
  --light-color: #000000;
  --dark-color: #374151
`
)

const courseData = computed(() => store.state.courseData)
const user = computed(() => store.getters.getUserData)
const tenant = computed(() => store.state.tenant)
const meta = computed(() => store.state.meta)
const getSideMenuView = computed(() => store.getters.getSideMenuView)
const getCountry = computed(() => store.getters.getCountry)

watch(route, (val, old) => {
	if (val && old && val.fullPath !== old.fullPath) {
		store.dispatch('resetMeta')
		store.dispatch('resetTab')
		store.dispatch('updateMeta', {
			...meta.value,
			title: `${val.name} - ${tenant.value?.meta?.title || tenant.value?.name || ''}`
		})
		store.dispatch('hideModal')
		store.dispatch('hideOverlay')
	}
})

const init = () => {
	const timezone = DateTime.local().toFormat('z')

	if (!user.value?.data?.timezone) {
		saveDocument({ ref: user.value.ref, type: 'update', data: { timezone } }).catch((error) => {
			console.error('🚀 ~ file: App.vue ~ line 225 ~ init ~ error', error)
		})
	}

	if (!user.value?.data?.region) {
		saveDocument({
			ref: user.value.ref,
			type: 'update',
			data: { region: getCountry.value }
		}).catch((error) => {
			console.error('🚀 ~ file: App.vue ~ line 234 ~ init ~ error', error)
		})
	}
	if (!getSideMenuView.value) {
		store.dispatch('updateSideMenuView', 'member')
	}
	if (user.value.data.company) {
		fetchCompanyAccess()
	}
	if (!Gleap.isUserIdentified() && window.location.hostname !== 'localhost') {
		const data = {
			name: user.value.data.firstName + ' ' + user.value.data.lastName,
			email: user.value.data.email,
			companyId: tenant.value.id,
			companyName: tenant.value.name
		}

		Gleap.identify(user.value.id, data)
	}
}

watch(
	user,
	async (val, old) => {
		if (val && val !== old) {
			init()
		}
		if (val) {
			Sentry.setUser({ id: user.value.id })
		} else {
			Sentry.setUser(null)
		}
	},
	{ immediate: true }
)

onMounted(async () => {
	if (window.location.hostname !== 'localhost') {
		Gleap.initialize('cGv5MGAtNNYcOHjCsnKaIekrd8oZME1p')
	}
	const location = useBrowserLocation()
	const tenant = db.collection('tenant')
	const snapshots = store.state.unsubscribe

	const maintenance = db.collection('komunily').doc('maintenance')
	const unsub = maintenance.onSnapshot((doc) => {
		const data = doc.data()
		if (data.description && data.status) {
			const stateValue = {
				active: true,
				description: data.description,
				status: data.status
			}
			if (data.tenants) {
				stateValue.tenants = data.tenants
			} else {
				stateValue.tenants = []
			}
			maintenanceMode.value = stateValue
			maintenance.value = true
		} else {
			maintenance.value = false
			maintenanceMode.value = {
				active: false,
				description: '',
				status: '',
				tenants: []
			}
		}
		snapshots.push(unsub)
	})

	const unsubscribe = await tenant
		.where('domains', 'array-contains', location.value.host)
		.limit(1)
		.onSnapshot((querySnapshot) => {
			if (!querySnapshot.empty) {
				const data = querySnapshot.docs[0].data()
				store.dispatch('updateTenant', {
					id: querySnapshot.docs[0].id,
					...data,
					ref: querySnapshot.docs[0].ref
				})
				store.dispatch('updateMeta', data.meta)

				store.commit('setUnsubscribe', snapshots)
			} else {
				console.error(
					"🚀 ~ file: App.vue ~ line 194 ~ .onSnapshot ~ `url doesn't exist`",
					`url doesn't exist`
				)
			}
			loaded.value = true
			snapshots.push(unsubscribe)
		})
})

const fetchCompanyAccess = async () => {
	if (store.state.companyAccess !== null) {
		const fetchCompanyAccess = functions.httpsCallable('fetchCompanyAccess')
		const { company } = user.value.data
		await fetchCompanyAccess({ company })
			.then((res) => {
				store.commit('setCompanyAccess', res.data)
			})
			.catch((error) => {
				console.error('🚀 ~ file: App.vue ~ line 221 ~ fetchCompanyAccess ~ error', error)
			})
	}
}
</script>
<style>
.container {
	display: table;
}
.content {
	display: table-cell;
	vertical-align: top;
}
.sidebar {
	display: table-cell;
	vertical-align: top;
}
@media (max-width: 767px) {
	th.font-normal.text-left.pr-16.pl-10 {
		padding: 8px 5px;
	}
	td.pr-16.pl-10.cursor-pointer {
		padding: 8px;
	}
}
</style>
