import { db, firestore } from '@root/firebaseDatabase'
import { showDanger } from '@utils/AlertService'
import { log } from '@reusables/logger'
import store from '../store'

/**
 * get document or collection with or without 'where' param
 * @param path string
 * @param type string: collection || doc
 * @param where Object: {field, filter, value, limit, orderBy: {field, direction?}}
 * if !direction provided orderBy is ascending
 * @returns {object|array}
 */
export const getDocument = async (path, type, where, infinite) => {
	let query
	const pathRef = db[type](path)
	if (where && where?.field && where?.filter && where?.value) {
		query = pathRef.where(where.field, where.filter, where.value)
		if (where.secondField && where.secondFilter && where.secondValue) {
			query = query.where(where.secondField, where.secondFilter, where.secondValue)
		}
	} else {
		query = pathRef
	}
	if (where?.orderBy) {
		// single order by as others require index as such user written query and document usage
		query = query.orderBy(where.orderBy.field, where.orderBy.direction)
		if (where.orderBy.secondField && where.orderBy.secondDirection) {
			query = query.orderBy(where.orderBy.secondField, where.orderBy.secondDirection)
		}
	}
	if (where?.lastItem) {
		query = query.startAfter(where.lastItem)
	}
	const limit = where?.limit ? where.limit : 999

	if (limit && type === 'collection') {
		query = query.limit(limit)
	}
	return new Promise((resolve, reject) => {
		return query
			.get()
			.then((response) => {
				if (type === 'collection') {
					const docs = []
					response.docs.forEach((doc) => {
						const docData = { data: doc.data(), ref: doc.ref, id: doc.id }
						if (infinite) {
							docData.defaultData = doc
						}
						docs.push(docData)
					})
					return resolve(docs)
				}
				const data = {
					data: response.data(),
					ref: response.ref,
					id: response.id
				}

				return resolve(data)
			})
			.catch((error) => {
				return reject(error)
			})
	})
}

/**
 * save Document via set or update
 * @param ref document reference
 * @param type string: set || update
 * @param data object
 * @param merge non-required parameter
 * @returns {object|array}
 */

export const saveDocument = ({ ref, type, data, merge = false, updatedReference = true }) => {
	const dataObject = data
	if (updatedReference) {
		const user = store.getters.getCurrentUser
		const updatedAt = firestore.FieldValue.serverTimestamp()
		const reference = db.doc(`tenant/${store.getters.getTenantId}/users/${user.uid}/`)
		dataObject.updatedAt = updatedAt
		dataObject.updatedBy = reference
	}
	return new Promise((resolve, reject) => {
		return ref[type](dataObject, { merge })
			.then(() => {
				log(
					ref.path,
					null,
					JSON.stringify(dataObject, (key, value) =>
						value instanceof firestore.FieldValue ? value.toString() : value
					)
				)
				return resolve(true)
			})
			.catch((error) => {
				return reject(error)
			})
	})
}

/**
 * Get data from Firebase Doc Reference
 * @param doc
 * @returns {Promise<undefined|*>}
 */

export const getDataFromDoc = async (doc) => {
	if (doc) {
		let data
		await doc.get().then(async (value) => {
			data = await value.data()
		})
		// eslint-disable-next-line no-return-await
		return data
	}
	return undefined
}

/**
 * Get total time for a child component
 * @param child
 * @returns {string|number}
 */
export const getChildTime = (child) => {
	if (child) {
		const { length } = child
		if (length) {
			const { hours, minutes, seconds } = length
			return (+hours || 0) * 60 + (+minutes || 0) + (+seconds || 0) / 60
		}
	}
	return 0.0
}

/**
 * Reducer function to calculate total time for a Course Element
 * @param accumulator
 * @param currentValue
 * @returns {*}
 */
const childLengthReducer = (accumulator, currentValue) => accumulator + +getChildTime(currentValue)

/**
 * Get total time for a Course Element
 * @param element
 * @returns {number|*}
 */
export const getElementTime = (element) => {
	if (element && element.children) {
		const { children } = element
		return children.reduce(childLengthReducer, 0)
	}
	return 0
}

/**
 * Reducer function to calculate total time for a Course
 * @param accumulator
 * @param currentValue
 * @returns {*}
 */
export const elementLengthReducer = (accumulator, currentValue) =>
	accumulator + +getElementTime(currentValue)

/**
 * Get Total time for the Course
 * @param course
 * @returns {number|*}
 */
export const getCourseTime = (course) => {
	if (course && course.elements) {
		const { elements } = course
		return elements.reduce(elementLengthReducer, 0).toFixed(2)
	}
	return 0
}

/**
 * Get time converted to an Object {hours, minutes, seconds}
 * @param time
 * @param format
 * @returns {string|{hours: number, seconds: number, minutes: number}}
 */
export const getTime = (time, format) => {
	if (time) {
		switch (format) {
			case 'hm':
				return {
					hours: Math.floor(time / 60),
					minutes: Math.floor(time)
				}
			case 'hs':
				return {
					hours: Math.floor(time / 60),
					seconds: Math.round((time - Math.floor(time)) * 60)
				}
			case 'ms':
				return {
					minutes: Math.floor(time),
					seconds: Math.round((time - Math.floor(time)) * 60)
				}
			case 'h':
				return {
					hours: Math.floor(time / 60)
				}
			case 'm':
				return {
					minutes: Math.floor(time)
				}
			case 's':
				return {
					seconds: Math.round((time - Math.floor(time)) * 60)
				}
			default:
				return {
					hours: Math.floor(time / 60),
					minutes: Math.floor(time % 60),
					seconds: Math.round((time - Math.floor(time)) * 60)
				}
		}
	}
	return { hours: 0, minutes: 0, seconds: 0 }
}

/**
 * Get string formatted time from Object Time
 * @param time
 * @returns {string}
 */
export const getTimeFormatted = (time, short) => {
	if (time) {
		let formattedTime = ''
		const { hours, minutes, seconds } = time
		if (hours > 0) {
			if (short) {
				formattedTime += `${hours}h `
			} else {
				formattedTime += `${hours} ${hours > 1 ? 'hours' : 'hour'} `
			}
		}
		if (minutes > 0) {
			if (short) {
				formattedTime += `${minutes}m `
			} else {
				formattedTime += `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} `
			}
		}
		if (seconds > 0) {
			if (short) {
				formattedTime += `${seconds}s`
			} else {
				formattedTime += `${seconds} ${seconds > 1 ? 'seconds' : 'second'} `
			}
		}
		return formattedTime
	}
	return ''
}

/**
 * Get path and collection to check if path exists
 * @async
 * @param path
 * @param collection
 * @param tenantId
 * @returns {Promise<boolean>}
 */
export const getDuplicatedPath = async (path, collection, tenantId, type) => {
	const where = {
		field: 'path',
		filter: '==',
		value: path
	}

	let duplicatedPath = false
	if (type === 'update') {
		where.path = path

		await getDocument(`/tenant/${tenantId}/${collection}/`, 'collection', where)
			.then(async (results) => {
				if (results.length === 1 && results[0].id !== path.id) {
					duplicatedPath = true
					showDanger('The path already exists')
				}
			})
			.catch((error) => {
				log('🚀 ~ file: DataService.js ~ line 253 ~ getDuplicatedPath ~ error', error)
				showDanger(error)
			})

		return duplicatedPath
	}

	await getDocument(`/tenant/${tenantId}/${collection}/`, 'collection', where)
		.then(async (results) => {
			if (results.length === 1) {
				duplicatedPath = true
				showDanger('The path already exists')
			}
		})
		.catch((error) => {
			log('🚀 ~ file: DataService.js ~ line 268 ~ getDuplicatedPath ~ error', error)
			showDanger(error)
		})

	await getDocument(`/tenant/${tenantId}/utilities/redirects/`, 'doc')
		.then((response) => {
			const redirects = response.data?.courses
			if (redirects) {
				if (Object.keys(redirects).includes(path)) {
					duplicatedPath = true
					showDanger('The path already exists')
				}
			}
		})
		.catch((error) => {
			log('🚀 ~ file: DataService.js ~ line 290 ~ awaitgetDocument ~ error', error)
		})
	return duplicatedPath
}
