import { AUTH_ERROR_CODES } from '@root/src/constants/Default'
import { log } from '@reusables/logger'
import { auth, db, functions, firestore } from '@root/firebaseDatabase'
import store from '../store/index'
import { showDanger, showSuccess } from './AlertService'

const AUTH_CODES = {
	'auth/wrong-password': 'The password is incorrect!',
	'auth/user-not-found': 'User does not exist!'
}

export const fetchAuthState = () => {
	return new Promise((resolve, reject) => {
		auth.onAuthStateChanged(async (res) => {
			if (res?.uid) {
				await store.dispatch('updateCurrentUser', res)
				const unsubscribe = await db
					.collection('tenant')
					.doc(res.tenantId)
					.collection('users')
					.doc(store.state.userOverride || res.uid)
					.onSnapshot((user) => {
						if (user.exists) {
							store.dispatch('updateUser', { data: user.data(), id: user.id, ref: user.ref })
							const snapshots = store.state.unsubscribe
							snapshots.push(unsubscribe)
							store.commit('setUnsubscribe', snapshots)
							resolve(user.data())
						}
					})
			} else {
				const { unsubscribe } = store.state
				if (typeof unsubscribe !== 'undefined') {
					unsubscribe.forEach((snapshot) => snapshot())
					store.commit('setUnsubscribe', [])
				}
				await store.dispatch('updateViewAs', undefined)
				await store.dispatch('updateCurrentUser', undefined)
				await store.dispatch('updateUser', undefined)
				reject()
			}
		})
	})
}

export const loginWithEmailAndPassword = (email, password, tenantId) => {
	return new Promise((resolve, reject) => {
		auth.tenantId = tenantId
		auth
			.signInWithEmailAndPassword(email, password)
			.then((user) => {
				store.dispatch('updateCurrentUser', user)

				showSuccess('User successfully logged in!', 2000)
				resolve(user)
			})
			.catch((error) => {
				// log not required, log should be on the parent call
				showDanger(AUTH_CODES[error.code] || AUTH_ERROR_CODES[error.code] || error.message, 5000)
				reject(error)
			})
	})
}

export const signUpWithEmailAndPassword = ({
	email,
	password,
	tenantId,
	phone,
	firstName,
	lastName,
	company
}) => {
	return new Promise((resolve, reject) => {
		const set = {
			email,
			createdAt: firestore.FieldValue.serverTimestamp()
		}
		if (phone) {
			set.phone = phone
		}
		if (firstName) {
			set.firstName = firstName
		}
		if (lastName) {
			set.lastName = lastName
		}
		if (company) {
			set.company = company
		}
		auth.tenantId = tenantId
		auth
			.createUserWithEmailAndPassword(email, password)
			.then(async (response) => {
				await db
					.collection('tenant')
					.doc(tenantId)
					.collection('users')
					.doc(response.user.uid)
					.set(set)
					.catch((error) => {
						log('🚀 ~ file: AuthService.js ~ line 94 ~ .then ~ error', error)
					})
				store.dispatch('updateCurrentUser', response)
				showSuccess('New user has been successfully signed up!', 5000)
				resolve(true)
			})
			.catch((error) => {
				log('🚀 ~ file: AuthService.js ~ line 99 ~ returnnewPromise ~ message', error)
				showDanger(AUTH_ERROR_CODES[error.code] || error.message, 5000)
				reject(error.message)
			})
	})
}

export const createNewTenant = (organizationName) => {
	return new Promise((resolve, reject) => {
		const createTenant = functions.httpsCallable('createTenant')
		createTenant({ displayName: organizationName.substr(0, 20) })
			.then(() => {
				// TODO: Add tenant data to Database
				showSuccess('New tenant has been successfully created!', 5000)
				resolve(true)
			})
			.catch((error) => {
				log('🚀 ~ file: AuthService.js ~ line 115 ~ returnnewPromise ~ message', error)
				showDanger(AUTH_ERROR_CODES[error.code] || error.message, 5000)
				reject(error.message)
			})
	})
}

export const forgotPassword = (userEmail) => {
	const tenant = store.getters.getTenant
	const forgotPass = functions.httpsCallable('userEmailForgotPassword')
	const data = {
		userEmail,
		tenantId: tenant.id,
		tenantURL: tenant.domain
	}
	return forgotPass(data)
}

export const resetPassword = (actionCode, newPassword) => {
	auth.tenantId = store.getters.getTenant.id

	return auth
		.verifyPasswordResetCode(actionCode)
		.then(() => {
			// Save the new password.
			return auth
				.confirmPasswordReset(actionCode, newPassword)
				.then(() => {
					// Password reset has been confirmed and new password updated.
					return 'Password has been successfully updated, return to login and login with your new password.'
				})
				.catch((error) => {
					log('🚀 ~ file: AuthService.js ~ line 147 ~ .then ~ error', error)
					// Error occurred during confirmation. The code might have expired or the
					// password is too weak.
					return error.message
				})
		})
		.catch((error) => {
			log('🚀 ~ file: AuthService.js ~ line 154 ~ resetPassword ~ error', error)
			// Invalid or expired action code. Ask user to try to reset the password
			// again.
			return error.message
		})
}
