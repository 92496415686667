import { createRouter, createWebHistory } from 'vue-router'
import adminRoutes from './AdminRoutes'
import userRoutes from './UserRoutes'
import editRoutes from './EditRoutes'
// import store from '../store/index'

// Core functions

const routes = [...adminRoutes, ...userRoutes, ...editRoutes]
const router = createRouter({
	history: createWebHistory('/'),
	routes
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
	const subsequentMiddleware = middleware[index]
	// If no subsequent Middleware exists,
	// the default `next()` callback is returned.
	if (!subsequentMiddleware) {
		return context.next()
	}

	return (...parameters) => {
		// Run the default Vue Router `next()` callback first.
		context.next(...parameters)
		// Then run the subsequent Middleware with a new
		// `nextMiddleware()` callback.
		const nextMiddleware = nextFactory(context, middleware, index + 1)
		subsequentMiddleware({ ...context, next: nextMiddleware })
	}
}

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)
	if (!to.matched.length) {
		return router.push({ name: 'Login' })
	}
	if (to.meta.middleware && from) {
		const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
		const context = {
			from,
			next,
			router,
			to
		}
		const nextMiddleware = nextFactory(context, middleware, 1)

		return middleware[0]({ ...context, next: nextMiddleware })
	}
	return next()
})

export default router
